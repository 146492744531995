.menu-vertical .ant-menu-item-selected{
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  /* background-color: #1e1e1e; */
}
/* .menu-vertical .ant-menu-item:hover {
  color: 'white' !important
} */
.menu-vertical {
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px;
  width: 100%;
  /* font-size: 1vw; */
  /* background-color: #1e1e1e; */

}
/* .ant-layout-sider {
  width: 240px;
} */

.sidebar .ant-layout-sider-trigger{
  border-radius: 4px;
  /* background-color: #1e1e1e; */
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /* z-index: 100;
  overflow: auto; */
  height: 100vh; /* Full height */
}

.content-layout {
  /* margin-left: 200px; Adjust this value to match the Sider's width when not collapsed */
  transition: margin-left 0.2s;
}

.ant-drawer-content {
  font-family: 'Montserrat', sans-serif;
}
/* .ant-layout-sider-trigger:where(.css-dev-only-do-not-override-o2upwn).ant-layout .ant-layout-sider-trigger {
  background-color: white;
} */
/* .ant-menu-item {
    color: #fff !important;
  }
  
  .ant-menu-submenu-title {
    color: #fff !important;
  }
  
  .ant-menu-submenu-open > .ant-menu-submenu-title,
  .ant-menu-submenu-active > .ant-menu-submenu-title,
  .ant-menu-submenu-title:hover {
    color: #fff !important;
  }
  
  .ant-menu-item-only-child {
    color: #fff !important;
  } */