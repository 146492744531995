body.dark-mode .menu-vertical .ant-menu-item-selected{
    /* font-weight: 600;
    font-family: 'Montserrat', sans-serif; */
    background-color: #1e1e1e;
  }
  /* .menu-vertical .ant-menu-item:hover {
    color: 'white' !important
  } */
  body.dark-mode .menu-vertical {
    /* font-family: 'Montserrat', sans-serif; */
    background-color: #2d2d2d;
    /* border-radius: 4px; */
    /* width: 100%; */
  }
  /* .sidebar {
    background-color: #1e1e1e;
  } */
  body.dark-mode .sidebar .ant-layout-sider-children {
    background-color: #1e1e1e;
    /* border-radius: 4px; */
  }
  body.dark-mode .sidebar .ant-layout-sider-trigger{
    /* border-radius: 4px; */
    background-color: #1e1e1e;
  }

  /* UserLayout.css */
  body.dark-mode .sidebar {
  /* position: fixed;
  top: 0;
  left: 0;
  bottom: 0; */
  /* border-radius: 4px; */
  /* z-index: 100;
  overflow: auto; */
   /* Full height */
  /* background: none; */
  background-color: #1e1e1e;
}
/* .content-layout {
  transition: margin-left 0.2s;
} */
/* .ant-drawer-content {
  font-family: 'Montserrat', sans-serif;
} */

/* .content-layout.collapsed {
  margin-left: 80px; 
} */

  /* .ant-layout-sider-trigger:where(.css-dev-only-do-not-override-o2upwn).ant-layout .ant-layout-sider-trigger {
    background-color: white;
  } */
  /* .ant-menu-item {
      color: #fff !important;
    }
    
    .ant-menu-submenu-title {
      color: #fff !important;
    }
    
    .ant-menu-submenu-open > .ant-menu-submenu-title,
    .ant-menu-submenu-active > .ant-menu-submenu-title,
    .ant-menu-submenu-title:hover {
      color: #fff !important;
    }
    
    .ant-menu-item-only-child {
      color: #fff !important;
    } */