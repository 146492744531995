.tag-card{
  /* position: relative; */
  /* max-width: 250px; */
  /* height: 100%; */
  border:4px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  cursor: pointer;
}
.tag-card img{width: 100%; max-height: 220px; min-height: 220px;}
.insights-btn {
  position: absolute;
  bottom: 15px;
  right: 10px;
  display: none; /* Initially hidden */
  opacity: 0;
  transition: opacity 0.3s ease;
}
.tag-card:hover .insights-btn {
  display: block;
  opacity: 1;
}

.tag-icon{
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: start;
    color: rgb(255, 255, 255);
    width: 90px;
    position: absolute;
    height: 90px;
    border-radius: 6px;
}
.tag-text{
  transform: rotate(312deg);
  position: relative;
  top: -15%;
  left: 15%;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.tag-product{
  max-width: 130px;
  position: relative;
  cursor: pointer;
}
.tag-product-icon{
  display: inline-block;
  position: absolute;
  right: 4px;
  top: 4px;
}
.tag-product-img{
  width: 100%;
  height: 80px;
}
.tag-product-name{
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
}
.product-select-icon{
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 10px;
}
